<template>
  <div>
    <Header/>
    <b-container 
      style="padding-top:70px;"
    >
      <b-row>
        <b-col>
          <h1>区分・タグ編集</h1>
        </b-col>
      </b-row>

      <dl class="row mt-2">
        <dt class="col-auto text-nowrap formLabel">
          登録済み大区分
        </dt>
        <b-col>
          <b-button 
            @click="modeChange('main')" 
            variant="outline-success"
          >
            大区分並び替え
          </b-button>
        </b-col>

        
        <dd v-if="mainClassSortflag==0">
          <b-form-input 
            v-model="MainClassAdd" 
            placeholder="追加する区分を入力してください"
          ></b-form-input>
          <b-row >
            <b-col>
              <b-button 
                @click="addMaineClass" 
                pill 
                variant="outline-primary"
              >
                大区分追加
              </b-button>
            </b-col>
            <b-col style="text-align:right;">
              <b-button 
                @click="deleteMaineClass" 
                pill 
                variant="outline-danger" 
                class="float-right" 
              >
                区分削除
              </b-button>
            </b-col>
          </b-row>
        </dd >

        <dd 
          class="col-auto" 
          v-if="mainClassSortflag==0"
        >
          <div 
            v-for="(classMain,index) in facilityTypes"
            :key="index" 
            class="text-nowrap form-check-inline"
          >
            <input 
              name="MaineClass" 
              v-model="MainClassID" 
              class="form-check-radio" 
              type="radio"  
              :id="classMain.inClassificationID" 
              :value="classMain.inClassificationID"
            >
            <label 
              class="form-check-label" 
              :for="classMain.inClassificationID"
            >
              {{ classMain.vcClassificationName }}
            </label>
          </div>
        </dd>

        <!--並び替え時表示-->
        <dd v-if="mainClassSortflag==1">
          <b-row >
            <b-col >
              <b-button 
                @click="sortClass('main')" 
                pill 
                variant="outline-primary" 
                class="float-right" 
              >並び替え登録</b-button>
            </b-col>
          </b-row>
        </dd >


        <dd 
          class="col-auto" 
          v-if="mainClassSortflag==1"
        >
          <draggable 
            :options="options" 
            v-model="facilityTypes"
            draggable=".ddSort"
          >
            <div 
              v-for="(sortMainClassIDs,index) in facilityTypes"
              :key="index" 
              class="text-nowrap form-check-inline ddSort"
            >
              {{ sortMainClassIDs.vcClassificationName }}
            </div>
          </draggable>
        </dd>
      </dl>

      <hr>

      <dl 
        v-if="MainClassID!==0 && middleClassSortflag==0" 
        class="row mt-2" 
      >
        <dt  class="col-auto text-nowrap formLabel">
          {{this.selectMainClassName}}の中区分
        </dt>
        <b-col>
          <b-button 
            @click="modeChange('middle')" 
            variant="outline-success"
          >中区分並び替え</b-button>
        </b-col>

        <dd >
          <b-form-input 
            v-model="middleClassAdd" 
            placeholder="追加する区分を入力してください"
          ></b-form-input>
          <b-row >
            <b-col>
              <b-button 
                @click="addMuddleClass" 
                pill 
                variant="outline-primary"
              >中区分追加</b-button>
            </b-col>
            <b-col style="text-align:right;">
              <b-button 
                @click="deleteMiddleClass" 
                pill 
                variant="outline-danger" 
                class="float-right" 
              >区分削除</b-button>
            </b-col>
          </b-row>
        </dd >

        <dd class="col-auto">
          <div 
            v-for="(classMiddle,index) in middleClassList" 
            :key="index" 
            class="text-nowrap form-check-inline"
          >
            <input 
              name="subClass" 
              v-model="middleClassID" 
              class="form-check-radio" 
              type="radio"  
              :id="classMiddle.inClassificationID" 
              :value="classMiddle.inClassificationID"
            >
            <label 
              class="form-check-label" 
              :for="classMiddle.inClassificationID"
            >
              {{ classMiddle.vcMiddleClassName }}
            </label>
          </div>
        </dd>
      </dl>

      <dl 
        v-if="MainClassID!==0 && middleClassSortflag==1" 
        class="row mt-2" 
      >
        <dt  class="col-auto text-nowrap formLabel">
          {{this.selectMainClassName}}の中区分
        </dt>
        <b-col>
          <b-button 
            @click="modeChange('middle')" 
            variant="outline-success"
          >中区分並び替え</b-button>
        </b-col>

          <b-row >
            <b-col >
              <b-button 
                @click="sortClass('middle')" 
                pill 
                variant="outline-primary" 
                class="float-right" 
              >並び替え登録</b-button>
            </b-col>
          </b-row>

        <dd 
          class="col-auto" 
          v-if="MainClassID!==0 && middleClassSortflag==1"
        >
          <draggable 
            :options="options" 
            v-model="middleClassList" 
            draggable=".ddSort"
          >
            <div 
              v-for="(sortMiddleClassIDs,index) in middleClassList" 
              :key="index" 
              class="text-nowrap form-check-inline ddSort"
            >
              {{ sortMiddleClassIDs.vcMiddleClassName }}
            </div>
          </draggable>
        </dd>
      </dl>


      <dl 
        v-if="MainClassID===0" 
        class="row mt-2"
      >
        <dt class="col-auto text-nowrap formLabel">
          大区分を選択してください
        </dt>
      </dl>
      <hr>

      <dl 
        v-if="MainClassID!==0" 
        class="row mt-2"
      >
        <dt class="col-auto text-nowrap formLabel">
          {{this.selectMainClassName}}の登録済みタグ
        </dt>
        <b-col>
          <b-button 
            @click="modeChange('tag')" 
            variant="outline-success"
          >
            タグ並び替え
          </b-button>
        </b-col>

        <dd v-if="tagSortflag===0">
          <b-form-input 
            v-model="tagAdd" 
            placeholder="追加するタグを入力してください"
          ></b-form-input>
          <b-row >
            <b-col>
              <b-button 
                @click="addTag" 
                pill 
                variant="outline-primary"
              >
                タグ追加
              </b-button>
            </b-col>
            <b-col style="text-align:right;">
              <b-button 
                @click="deletetag" 
                pill 
                variant="outline-danger" 
                class="float-right" 
              >
                タグ削除
              </b-button>
            </b-col>
          </b-row>
        </dd >

        <dd 
          class="col-auto" 
          v-if="tagSortflag===0"
        >
          <div 
            style="font-weight:bold;"
          >
            管理者承認済みタグ
          </div>
          <div 
            v-for="(adminTag,index) in approvalTagList" 
            :key="index" 
            class="text-nowrap form-check-inline"
          >
            <input 
              name="tags" 
              v-model="selectTag" 
              type="checkbox"  
              :id="adminTag.vcTagName" 
              :value="adminTag.inTagID"
            >
            <label 
              :for="adminTag.vcTagName"
            >
              {{ adminTag.vcTagName }}
            </label>
          </div>

          <div 
            style="font-weight:bold;"
          >
            ユーザー追加タグ
          </div>
          <b-button 
            @click="approvavalTags" 
            pill 
            variant="outline-warning"
          >
            タグ承認
          </b-button>
          <br>
          <div 
            v-for="(userTag,index) in userPushTagList" 
            :key="index" 
            class="text-nowrap form-check-inline"
          >
            <input 
              name="tags" 
              v-model="selectTag" 
              type="checkbox"  
              :id="userTag.vcTagName" 
              :value="userTag.inTagID"
            >
            <label 
              :for="userTag.vcTagName"
            >
              {{ userTag.vcTagName }}
            </label>
          </div>
        </dd>

        <dd v-if="tagSortflag===1">
          <b-row >
            <b-col >
              <b-button 
                @click="sortClass('tag')" 
                pill 
                variant="outline-primary" 
                class="float-right" 
              >
                並び替え登録
              </b-button>
            </b-col>
          </b-row>

          <div 
            style="font-weight:bold;"
          >
            管理者承認済みタグ
          </div>
          <draggable 
            :options="options" 
            v-model="approvalTagList" 
            draggable=".ddSort"
          >
            <div 
              v-for="(adminTag,index) in approvalTagList" 
              :key="index" 
              class="text-nowrap form-check-inline ddSort"
            >
              {{ adminTag.vcTagName }}
            </div>
          </draggable>

          <div 
            style="font-weight:bold;"
          >
            ユーザー追加タグ
          </div>
          <br>
          <draggable 
            :options="options" 
            v-model="userPushTagList" 
            draggable=".ddSort"
          >
            <div 
              v-for="(userTag,index) in userPushTagList" 
              :key="index" 
              class="text-nowrap form-check-inline ddSort"
            >
              {{ userTag.vcTagName }}
            </div>
          </draggable>
        </dd>
      </dl>

    </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import { Apimixin } from '../../mixins/api'
import draggable from 'vuedraggable'

export default {
  components: {
    Header,
    draggable
  },
  mixins:[Apimixin],

  title:"区分・タグ編集",
  data() {
    return {
      facilityTypes:[],
      facilityTags:[],
      middleFacilityTypes:[],
      role:'',
      userName:'',
      MainClassAdd:"",
      MainClassID:0,
      selectMainClassName:"",
      mainClassSortflag:0,
      sortMainClassIDs:[],
      middleClassAdd:'',
      middleClassID:0,
      sortMiddleClassIDs:[],
      selectMiddleName:"",
      middleClassSortflag:0,
      tagAdd:"",
      selectTag:[],
      sortTagIDs:[],
      tagSortflag:0,
      middleClassList:[],
      approvalTagList:[],
      userPushTagList:[],
      options: {
          animation: 200
      },

    }
  },
  created() {
    //区分取得
    this.getClass
  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userName = sessionStorage.getItem('userName')
  },
  methods: {
    async getTags(mainID){
      this.approvalTagList=[]
      this.userPushTagList=[]
      await this.getTagAdmin(mainID)
    },
    //モード切り替え
    modeChange(type){
      if(type=='main'){
        if(this.mainClassSortflag==0){
          this.mainClassSortflag=1
        }else if(this.mainClassSortflag==1){
          this.mainClassSortflag=0
        }
      }else if(type=='middle'){
        if(this.middleClassSortflag==0){
          this.middleClassSortflag=1
        }else if(this.middleClassSortflag==1){
          this.middleClassSortflag=0
        }
      }else if(type=='tag'){
        if(this.tagSortflag==0){
          this.tagSortflag=1
        }else if(this.tagSortflag==1){
          this.tagSortflag=0
        }
      }
    },
    //区分並び替え
    sortClass(type){
      let sortVal=""
      if(type=="main"){
        type="class"
        this.sortMainClassIDs=this.facilityTypes.map(val => { return val })
        for(let i =0; i<this.sortMainClassIDs.length;i++){
          this.sortMainClassIDs[i]['inSort']=i+1
          this.sortMainClassIDs[i]['type']="upData"
        }
        sortVal=this.sortMainClassIDs
      }else if (type=="middle"){
        type="class"
        this.sortMiddleClassIDs=this.middleClassList.map(val => { return val })
        for(let i =0; i<this.sortMiddleClassIDs.length;i++){
          this.sortMiddleClassIDs[i]['inSort']=i+1
          this.sortMiddleClassIDs[i]['type']="upData"
        }
        sortVal=this.sortMiddleClassIDs
      }else if(type=="tag"){
        this.sortTagIDs=this.approvalTagList.map(val => { return val })
        for(let i =0; i<this.sortTagIDs.length;i++){
          this.sortTagIDs[i]['inSort']=i+1
          this.sortTagIDs[i]['type']="upData"
        }
        sortVal=this.sortTagIDs
        let userIDs=this.userPushTagList.map(val => { return val })
        for(let i =0; i<userIDs.length;i++){
          userIDs[i]['inSort']=i+1
          userIDs[i]['type']="upData"
          sortVal.push(userIDs[i])
        }
      }

      this.$swal({
          icon: 'question',
          text: '並び替えを行いますか？',
          showCancelButton : true,
          confirmButtonText : '登録する',
          cancelButtonText : 'やめる',
          reverseButtons : true
        })
        .then((isSort) => {
          if (isSort.isConfirmed) {
            let resurt = this.sortClassApi(type,sortVal)
            console.log(resurt)
          } else {
            this.$swal({
              icon: 'error',
              text: 'キャンセルボタンが押されました。'
            });
          }
        })
    },
    //大区分登録
    addMaineClass(){
      for(let val in this.facilityTypes){
        if(this.facilityTypes[val].vcClassificationName==this.MainClassAdd){
          return this.$swal({
              icon: 'error',
              text: '登録済み区分です。'
            });
        }
      }
      if(this.MainClassAdd=="" || this.MainClassAdd==" "){
          this.$swal({
            icon: 'error',
            text: '登録名を入力してください。'
          });
      }else{        
        this.$swal({
          icon: 'question',
          text: '区分名 [ '+this.MainClassAdd+ '] の登録を行いますか？',
          showCancelButton : true,
          confirmButtonText : '登録する',
          cancelButtonText : 'やめる',
          reverseButtons : true
        })
        .then((isDelete) => {
          if (isDelete.isConfirmed) {
            let resurt = this.newClass(this.MainClassAdd,0,"",this.facilityTypes.length+2 )
            console.log(resurt)
          } else {
            this.$swal({
              icon: 'error',
              text: 'キャンセルボタンが押されました。'
            });
          }
        })
      }      
    },
    //中区分登録
    addMuddleClass(){
      for(let val in this.middleClassList){
        if(this.middleClassList[val].vcMiddleClassName==this.middleClassAdd){
          return this.$swal({
              icon: 'error',
              text: '登録済み区分です。'
            });
        }
      }
      if(this.middleClassAdd=="" || this.middleClassAdd==" "){
          this.$swal({
            icon: 'error',
            text: '登録名を入力してください。'
          });
      }else{        
        this.$swal({
          icon: 'question',
          text: '区分名 [ '+this.middleClassAdd+ '] の登録を行いますか？',
          showCancelButton : true,
          confirmButtonText : '登録する',
          cancelButtonText : 'やめる',
          reverseButtons : true
        })
        .then((isDelete) => {
          if (isDelete.isConfirmed) {
            this.newClass(this.selectMainClassName,this.MainClassID,this.middleClassAdd,this.middleClassList.length+2 )
          } else {
            this.$swal({
              icon: 'error',
              text: 'キャンセルボタンが押されました。'
            });
          }
        })
      }
    },
    //タグ登録
    addTag(){
      for(let val in this.facilityTags){
        if(this.facilityTags[val].vcMiddleClassName==this.tagAdd){
          return this.$swal({
              icon: 'error',
              text: '登録済みタグです。'
            });
        }
      }
      if(this.tagAdd=="" || this.tagAdd==" "){
          this.$swal({
            icon: 'error',
            text: '登録名を入力してください。'
          });
      }else{        
        this.$swal({
          icon: 'question',
          text: 'タグ名 [ '+this.tagAdd+ '] の登録を行いますか？',
          showCancelButton : true,
          confirmButtonText : '登録する',
          cancelButtonText : 'やめる',
          reverseButtons : true
        })
        .then((isDelete) => {
          if (isDelete.isConfirmed) {
            this.newTag(this.MainClassID,this.tagAdd,this.facilityTags.length+2 )
          } else {
            this.$swal({
              icon: 'error',
              text: 'キャンセルボタンが押されました。'
            });
          }
        })
      }
    },
    //大区分削除
    deleteMaineClass(){
      let deleteClassIDs=[]
      let deleteTagIDs=[]
      if (this.middleClassList.length!=0){
        for(let key in this.middleClassList){
          deleteClassIDs.push(this.middleClassList[key].inClassificationID)
        }
      }
      deleteClassIDs.push(this.MainClassID)
      if (this.facilityTags.length!=0){
        for(let key in this.facilityTags){
          deleteTagIDs.push(this.facilityTags[key].inTagID)
        }
      }
      this.$swal({
        icon: 'warning',
        text: '区分名 [ '+this.selectMainClassName+ '] の削除を行いますか？',
        showCancelButton : true,
        confirmButtonText : '削除する',
        cancelButtonText : 'やめる',
        reverseButtons : true
      })
      .then((isDelete) => {
        if (isDelete.isConfirmed) {
          return this.deleteClass(deleteClassIDs,deleteTagIDs)
        } else {
          this.$swal({
            icon: 'error',
            text: 'キャンセルボタンが押されました。'
          });
        }
      })
    },
    //中区分削除
    deleteMiddleClass(){
      let deleteClassID=[]
      let deleteTagIDs=[]
      deleteClassID.push(this.middleClassID)

      this.$swal({
        icon: 'warning',
        text: '中区分名 [ '+this.selectMiddleName+ '] の削除を行いますか？',
        showCancelButton : true,
        confirmButtonText : '削除する',
        cancelButtonText : 'やめる',
        reverseButtons : true
      })
      .then((isDelete) => {
        if (isDelete.isConfirmed) {
          return this.deleteClass(deleteClassID,deleteTagIDs)
        } else {
          this.$swal({
            icon: 'error',
            text: 'キャンセルボタンが押されました。'
          });
        }
      })
    },
    //タグ削除
    deletetag(){
      this.$swal({
        icon: 'warning',
        text: 'タグの削除を行いますか？',
        showCancelButton : true,
        confirmButtonText : '削除する',
        cancelButtonText : 'やめる',
        reverseButtons : true
      })
      .then((isDelete) => {
        if (isDelete.isConfirmed) {
          return this.deleteTags(this.selectTag)
        } else {
          this.$swal({
            icon: 'error',
            text: 'キャンセルボタンが押されました。'
          });
        }
      })
    },
    //タグ承認
    approvavalTags(){      
      this.$swal({
        icon: 'warning',
        text: '選択中タグの承認を行いますか？',
        showCancelButton : true,
        confirmButtonText : '承認する',
        cancelButtonText : 'やめる',
        reverseButtons : true
      })
      .then((isApproval) => {
        if (isApproval.isConfirmed) {
          let appList=[]
          for(let userTag in this.userPushTagList){
            if(this.selectTag.indexOf(this.userPushTagList[userTag].inTagID) !== -1){
              let pushVal={}
              pushVal["tagID"]=this.userPushTagList[userTag].inTagID
              pushVal["type"]='upData'
              pushVal["tagName"]=this.userPushTagList[userTag].vcTagName
              pushVal["sort"]=this.userPushTagList[userTag].inSort
              pushVal["kinds"]=0
              appList.push(pushVal)
            }
          }
          return this.approvalTag(appList)
        } else {
          this.$swal({
            icon: 'error',
            text: 'キャンセルボタンが押されました。'
          });
        }
      })
    },
  },
  computed: {
    //区分取得
    getClass(){
      return this.getFacilityType();
    },
  },
  watch:{
    //大区分選択時発火・中区分及びタグの取得
    'MainClassID': async function(){
      this.middleClassList=[]
      this.selectTag=[]
      this.approvalTagList=[]
      this.userPushTagList=[]
      this.selectMainClassName=""
      this.facilityTypes.filter(
        (val)=>{
          if(val.inClassificationID==this.MainClassID){
            this.selectMainClassName=val.vcClassificationName
          }
        }
      )
      this.middleFacilityTypes.filter(
        (value) =>{
          if(value.inParentClassID===this.MainClassID){
            this.middleClassList.push(value)
          }
        }
      )
      this.getTags(this.MainClassID)
    },
    //中区分選択時
    'middleClassID': function(){
      this.middleClassList.filter(
        (val)=>{
          if(val.inClassificationID==this.middleClassID){
            this.selectMiddleName=val.vcMiddleClassName
          }
        }
      )
    },
    'facilityTypes':function(){
      this.middleFacilityTypes.filter(
        (value) =>{
          if(value.inParentClassID===this.MainClassID){
            this.middleClassList.push(value)
          }
        }
      )
    },
    "facilityTags":function(){
      this.approvalTagList=[]
      this.userPushTagList=[]

      for(let tagKey in this.facilityTags){
        if(this.facilityTags[tagKey].inTagKinds===0){
          this.approvalTagList.push(this.facilityTags[tagKey])
        }
        else if(this.facilityTags[tagKey].inTagKinds===1){
          this.userPushTagList.push(this.facilityTags[tagKey])
        }
      }
    },
  }
}
</script>

<style scoped>
  .ddSort {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    border: 1px solid #7f7f7f;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .ddSort:hover {
    cursor: grab;
  }
  .ddSort:active {
    cursor: grabbing;
  }
</style>
