<template>
<div>
  <Header/>
  <b-container 
    style="max-width:95%; padding-top:70px;"
  >
    <UserListSideBar
      @searchUser="filteringUser" 
      :userLists="userLists"
      :checkUser="checkUser"
    />
    <b-overlay 
      :show="showLoader" 
      rounded="sm"
    >
    
      <b-row>
        <b-col>
          <h1>投稿写真集計</h1>
          <b-button 
            v-b-toggle.userList
            variant="primary"
          >
            ユーザー一覧
          </b-button>
          <b-button 
            class="ms-2"
            variant="success"
            @click="allUserList"
          >
            全ユーザー
          </b-button>
        </b-col>
        <b-col >
          <span v-if="!searchFlag">
            {{ toMonth }}月の投稿写真数：<b>{{ toMonthTotalCount }}枚</b><br>
          </span>
          <span v-if="!searchFlag">
            {{ toLastMonth }}月の投稿写真数：<b>{{ toLastMonthTotalCount }}枚</b><br>
          </span>
          <span v-if="!searchFlag">
            トータル投稿写真数：<b>{{ toAllMonthCount }}枚</b>
          </span>
          <span 
            class="text-nowrap" 
            v-else
          >
            {{ from }}～{{ to }}の投稿写真数：<b>{{ toAllMonthCount }}枚</b>
          </span>
        </b-col>
        <b-col >
          <span v-if="!searchFlag">
            {{ toMonth }}月の承認枚数：<b>{{ toMonthApprovalCount }}枚</b><br>
          </span>
          <span v-if="!searchFlag">
            {{ toLastMonth }}月の承認枚数：<b>{{ toLastMonthApprovalCount }}枚</b><br>
          </span>
          <span v-if="!searchFlag">
            トータル承認枚数：<b>{{ toAllMonthApprovalCount }}枚</b>
          </span>
          <span
            class="text-nowrap"
            v-else
          >
            {{ from }}～{{ to }}承認枚数：<b>{{ toAllMonthApprovalCount  }}枚</b>
          </span>
        </b-col>
        <b-col>
          <h5>期間絞り込み</h5>
          <span>
            <small>From</small>
            <vuejs-datepicker 
              name="input_month"
              :language="ja"
              :format="DatePickerFormat"
              :initial-view="'year'"
              :minimum-view="'month'"
              :maximum-view="'year'"
              v-model="searchFrom"
            />
          </span>
          <span>
            <small>To</small>
            <vuejs-datepicker 
              name="input_month"
              :language="ja"
              :format="DatePickerFormat"
              :initial-view="'year'"
              :minimum-view="'month'"
              :maximum-view="'year'"
              v-model="searchTo"
            />
          </span>
        </b-col>
        <b-col>
          <b-button 
            variant="primary" 
            @click="getMonthPeriodCount(searchFrom,searchTo)"
          >
            絞り込み
          </b-button>
          <b-button
            class="ms-2"
            variant="success"
            @click="processSummaryList()"
          >
            全期間
          </b-button>
        </b-col>
      </b-row>

      <div 
        class="table-responsive " 
      >
        <table 
          class="table table_sticky table-striped table-bordered"
        >
          <thead 
            class="table-secondary"
          >
            <tr>
              <th 
                scope="col" 
                style="width:6%"
                class="text-center"
              >
                ユーザー名
              </th>
              <th 
                scope="col" 
                style="width:10%"
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ toMonth }}月の投稿写真数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ toLastMonth }}月の投稿写真数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  トータル投稿写真数
                </span>
                <span v-else>
                  {{ from }}～{{ to }}の投稿写真数
                </span>
              </th>
              <th 
                scope="col" 
                style="width:10%"
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ toMonth }}月の承認枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ toLastMonth }}月の承認枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  トータル承認枚数
                </span>
                <span v-else>
                  {{ from }}～{{ to }}承認枚数
                </span>
              </th>
              <th 
                scope="col" 
                style="width:10%"
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ toMonth }}月の差し戻し枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ toLastMonth }}月の差し戻し枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  トータル差し戻し枚数
                </span>
                <span v-else>
                  {{ from }}～{{ to }}差し戻し枚数
                </span>
              </th>
              <th 
                scope="col" 
                style="width:10%"
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ toMonth }}月の未承認枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ toLastMonth }}月の未承認枚数<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  トータル未承認枚数
                </span>
                <span v-else>
                  {{ from }}～{{ to }}未承認枚数
                </span>
              </th>
            </tr>
          </thead>
          <tbody 
            v-if="searchLists.length === 0"
          >
            <tr 
              v-for="summaryList in summaryLists" 
              :key="summaryList.userID"
            >
              <th 
                scope="row" 
                class="text-center"
              >
                {{ summaryList.userName }}
              </th>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toMonthTotalCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toLastMonthTotalCount }}枚<br>
                </span>
                <span>
                  {{ summaryList.toMonthPeriodTotalCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toMonthApprovalCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toLastMonthApprovalCount }}枚<br>
                </span>
                <span>
                  {{ summaryList.toMonthPeriodApprovalCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toMonthRemandCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toLastMonthRemandCount }}枚<br>
                </span>
                <span>
                  {{ summaryList.toMonthPeriodRemandCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toMonthUnapprovedCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ summaryList.toLastMonthUnapprovedCount }}枚<br>
                </span>
                <span>
                  {{ summaryList.toMonthPeriodUnapprovedCount }}枚<br>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr 
              v-for="searchList in searchLists" 
              :key="searchList.userID"
            >
              <th 
                scope="row" 
                class="text-center"
              >
                {{ searchList.userName }}
              </th>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toMonthTotalCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toLastMonthTotalCount }}枚<br>
                </span>
                <span>
                  {{ searchList.toMonthPeriodTotalCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toMonthApprovalCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toLastMonthApprovalCount }}枚<br>
                </span>
                <span>
                  {{ searchList.toMonthPeriodApprovalCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toMonthRemandCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toLastMonthRemandCount }}枚<br>
                </span>
                <span>
                  {{ searchList.toMonthPeriodRemandCount }}枚<br>
                </span>
              </td>
              <td 
                class="text-center"
              >
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toMonthUnapprovedCount }}枚<br>
                </span>
                <span 
                  v-if="!searchFlag"
                >
                  {{ searchList.toLastMonthUnapprovedCount }}枚<br>
                </span>
                <span>
                  {{ searchList.toMonthPeriodUnapprovedCount }}枚<br>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import UserListSideBar from '../../components/UserListSideBar.vue'
import moment from 'moment'
import { Apimixin } from '../../mixins/api'
import {ja} from 'vuejs-datepicker/dist/locale'
import VuejsDatepicker from 'vuejs-datepicker'

export default {
  title: '投稿写真集計',
  components: {
    Header,
    VuejsDatepicker,
    UserListSideBar
  },
  mixins:[Apimixin],
  data() {
    return {
      userLists:[],
      summaryLists:[],
      toDay:'',
      toYear:'',
      toMonth:'',
      toLastMonth:'',
      toLastDay:'',
      ja:ja,
      searchFrom:'',
      searchTo:'',
      DatePickerFormat:'yyyy-MM',
      searchFlag:false,
      from:'',
      to:'',
      showLoader:false,
      showUserList:false,
      toMonthTotalCount:0,
      toLastMonthTotalCount:0,
      toAllMonthCount:0,
      toMonthApprovalCount:0,
      toLastMonthApprovalCount:0,
      toAllMonthApprovalCount:0,
      searchLists:[],
      checkUser:[],
      periodMonthFlag:false
    }
  },
  created() {
    this.toDay = moment().format("YYYY-MM")
    this.toLastDay = moment().add(-1, 'M').format("YYYY-MM")
    this.toYear = moment().format("YYYY")
    this.toMonth = moment().format("MM")
    this.toLastMonth = moment().add(-1,'M').format("MM")
  },
  mounted() {
    this.showLoader = true
    this.getPhotoSummaryList()
  },
  methods: {
    //当月の撮影情報取得
    getToMonthCount() {
      this.toMonthTotalCount = 0
      this.toMonthApprovalCount = 0
      if(this.searchLists.length === 0) {
        for(let i = 0; i < this.userLists.length;i++) {
          this.summaryLists[this.userLists[i].inUserID]['toMonthTotalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthApprovalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthRemandCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthUnapprovedCount']  = 0

          //当月トータル撮影数
          if(this.summaryLists[this.userLists[i].inUserID].allCount.length !== 0 &&
            this.summaryLists[this.userLists[i].inUserID].allCount[0].regist_time == this.toDay) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthTotalCount'] 
              = this.summaryLists[this.userLists[i].inUserID].allCount[0].totalCount
              this.toMonthTotalCount += this.summaryLists[this.userLists[i].inUserID].allCount[0].totalCount
          } 
          //当月承認数
          if(this.summaryLists[this.userLists[i].inUserID].approvalCount.length !== 0 &&
            this.summaryLists[this.userLists[i].inUserID].approvalCount[0].regist_time == this.toDay) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthApprovalCount'] 
              = this.summaryLists[this.userLists[i].inUserID].approvalCount[0].totalCount
            this.toMonthApprovalCount += this.summaryLists[this.userLists[i].inUserID].approvalCount[0].totalCount
          } 
          //当月差し戻し数
          if(this.summaryLists[this.userLists[i].inUserID].remandCount.length !== 0 &&
            this.summaryLists[this.userLists[i].inUserID].remandCount[0].regist_time == this.toDay) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthRemandCount'] 
              = this.summaryLists[this.userLists[i].inUserID].remandCount[0].totalCount
          } 
          //当月未承認数
          if(this.summaryLists[this.userLists[i].inUserID].unapprovedCount.length !== 0 &&
            this.summaryLists[this.userLists[i].inUserID].unapprovedCount[0].regist_time == this.toDay) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthUnapprovedCount'] 
              = this.summaryLists[this.userLists[i].inUserID].unapprovedCount[0].totalCount
          }
        }
      } else {
        for(let i = 0; i < this.searchLists.length;i++) {
          this.searchLists[i]['toMonthTotalCount'] = 0
          this.searchLists[i]['toMonthApprovalCount'] = 0
          this.searchLists[i]['toMonthRemandCount'] = 0
          this.searchLists[i]['toMonthUnapprovedCount']  = 0

          //当月トータル撮影数
          if(this.searchLists[i].allCount.length !== 0 &&
            this.searchLists[i].allCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthTotalCount'] 
              = this.searchLists[i].allCount[0].totalCount
              this.toMonthTotalCount += this.searchLists[i].allCount[0].totalCount
          } 
          //当月承認数
          if(this.searchLists[i].approvalCount.length !== 0 &&
            this.searchLists[i].approvalCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthApprovalCount'] 
              = this.searchLists[i].approvalCount[0].totalCount
            this.toMonthApprovalCount += this.searchLists[i].approvalCount[0].totalCount
          } 
          //当月差し戻し数
          if(this.searchLists[i].remandCount.length !== 0 &&
            this.searchLists[i].remandCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthRemandCount'] 
              = this.searchLists[i].remandCount[0].totalCount
          } 
          //当月未承認数
          if(this.searchLists[i].unapprovedCount.length !== 0 &&
            this.searchLists[i].unapprovedCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthUnapprovedCount'] 
              = this.searchLists[i].unapprovedCount[0].totalCount
          }
        }
      }
      
    },
    //先月の撮影情報取得
    getToLastMonthCount() {
      this.toAllMonthCount = 0
      this.toLastMonthTotalCount = 0
      this.toAllMonthApprovalCount = 0
      this.toLastMonthApprovalCount = 0
      if(this.searchLists.length === 0) {
        for(let i = 0; i < this.userLists.length;i++) {
          this.summaryLists[this.userLists[i].inUserID]['toLastMonthTotalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toLastMonthApprovalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toLastMonthRemandCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toLastMonthUnapprovedCount']  = 0
          //先月トータル撮影数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].allCount.length;t++) {
            this.toAllMonthCount +=  this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
            if(this.summaryLists[this.userLists[i].inUserID].allCount[t].regist_time == this.toLastDay) {
              this.summaryLists[this.userLists[i].inUserID]['toLastMonthTotalCount'] 
                = this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
                this.toLastMonthTotalCount += this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
            }
          }
          //先月承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].approvalCount.length;t++) {
            this.toAllMonthApprovalCount +=  this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
            if(this.summaryLists[this.userLists[i].inUserID].approvalCount[t].regist_time == this.toLastDay) {
              this.summaryLists[this.userLists[i].inUserID]['toLastMonthApprovalCount'] 
                = this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
                this.toLastMonthApprovalCount += this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
            }
          }
          //先月差し戻し数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].remandCount.length;t++) {
            if(this.summaryLists[this.userLists[i].inUserID].remandCount[t].regist_time == this.toLastDay) {
              this.summaryLists[this.userLists[i].inUserID]['toLastMonthRemandCount'] 
                = this.summaryLists[this.userLists[i].inUserID].remandCount[t].totalCount
            }
          }
          //先月未承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].unapprovedCount.length;t++) {
            if(this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].regist_time == this.toLastDay) {
              this.summaryLists[this.userLists[i].inUserID]['toLastMonthUnapprovedCount'] 
                = this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].totalCount
            }
          }
        }
      } else {
        for(let i = 0; i < this.searchLists.length;i++) {
          this.searchLists[i]['toLastMonthTotalCount'] = 0
          this.searchLists[i]['toLastMonthApprovalCount'] = 0
          this.searchLists[i]['toLastMonthRemandCount'] = 0
          this.searchLists[i]['toLastMonthUnapprovedCount']  = 0
          //先月トータル撮影数
          for(let t = 0;t < this.searchLists[i].allCount.length;t++) {
            this.toAllMonthCount +=  this.searchLists[i].allCount[t].totalCount
            if(this.searchLists[i].allCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthTotalCount'] 
                = this.searchLists[i].allCount[t].totalCount
                this.toLastMonthTotalCount += this.searchLists[i].allCount[t].totalCount
            }
          }
          //先月承認数
          for(let t = 0;t < this.searchLists[i].approvalCount.length;t++) {
            this.toAllMonthApprovalCount +=  this.searchLists[i].approvalCount[t].totalCount
            if(this.searchLists[i].approvalCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthApprovalCount'] 
                = this.searchLists[i].approvalCount[t].totalCount
                this.toLastMonthApprovalCount += this.searchLists[i].approvalCount[t].totalCount
            }
          }
          //先月差し戻し数
          for(let t = 0;t < this.searchLists[i].remandCount.length;t++) {
            if(this.searchLists[i].remandCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthRemandCount'] 
                = this.searchLists[i].remandCount[t].totalCount
            }
          }
          //先月未承認数
          for(let t = 0;t < this.searchLists[i].unapprovedCount.length;t++) {
            if(this.searchLists[i].unapprovedCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthUnapprovedCount'] 
                = this.searchLists[i].unapprovedCount[t].totalCount
            }
          }
        }
      }
    },
    //指定期間の撮影数表示
    getMonthPeriodCount(from,to) {
      if(from === 'all' && to === 'all' && this.checkUser.length === 0) {
        this.searchFlag = false
        this.searchFrom = ''
        this.searchTo = ''
        for(let i = 0; i < this.userLists.length;i++) {
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodTotalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodApprovalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodRemandCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodUnapprovedCount']  = 0
          //トータルの撮影数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].allCount.length;t++) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodTotalCount'] 
              += this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
          }
          //トータルの承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].approvalCount.length;t++) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodApprovalCount'] 
              += this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
          }
          //トータルの差し戻し数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].remandCount.length;t++) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodRemandCount'] 
              += this.summaryLists[this.userLists[i].inUserID].remandCount[t].totalCount
          }
          //トータルの未承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].unapprovedCount.length;t++) {
            this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodUnapprovedCount'] 
              += this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].totalCount
          }
        }
      } else if(from === 'all' && to === 'all' && this.checkUser.length !== 0)  {
          this.searchFlag = false
          this.searchFrom = ''
          this.searchTo = ''
          for(let i = 0; i < this.searchLists.length;i++) {
            this.searchLists[i]['toMonthPeriodTotalCount'] = 0
            this.searchLists[i]['toMonthPeriodApprovalCount'] = 0
            this.searchLists[i]['toMonthPeriodRemandCount'] = 0
            this.searchLists[i]['toMonthPeriodUnapprovedCount']  = 0
            //トータルの撮影数
            for(let t = 0;t < this.searchLists[i].allCount.length;t++) {
              this.searchLists[i]['toMonthPeriodTotalCount'] 
                += this.searchLists[i].allCount[t].totalCount
            }
            //トータルの承認数
            for(let t = 0;t < this.searchLists[i].approvalCount.length;t++) {
              this.searchLists[i]['toMonthPeriodApprovalCount'] 
                += this.searchLists[i].approvalCount[t].totalCount
            }
            //トータルの差し戻し数
            for(let t = 0;t < this.searchLists[i].remandCount.length;t++) {
              this.searchLists[i]['toMonthPeriodRemandCount'] 
                += this.searchLists[i].remandCount[t].totalCount
            }
            //トータルの未承認数
            for(let t = 0;t < this.searchLists[i].unapprovedCount.length;t++) {
              this.searchLists[i]['toMonthPeriodUnapprovedCount'] 
                += this.searchLists[i].unapprovedCount[t].totalCount
            }
          }

      } else if(from > to) {
        this.$swal({
          title: `絞込み期間が不正です。`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '閉じる',
        })
      } else if(from !== '' && to !== '' ){
        this.searchMonthPeriodCount(from,to)
      } else {
          this.$swal({
          title: `絞り込期間を入力してください`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '閉じる',
        })
      }
    },
    //期間の絞込み件数取得
    searchMonthPeriodCount(from,to) {
      this.toAllMonthCount = 0
      this.toAllMonthApprovalCount = 0
      from = moment(from).format("YYYY-MM")
      to = moment(to).format("YYYY-MM")
      this.from = moment(from).format("YYYY年MM月")
      this.to = moment(to).format("YYYY年MM月")
      this.searchFlag = true
      if(this.searchLists.length === 0) {
        for(let i = 0; i < this.userLists.length;i++) {
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodTotalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodApprovalCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodRemandCount'] = 0
          this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodUnapprovedCount']  = 0
          //絞込み期間の撮影数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].allCount.length;t++) {
            if(from <= this.summaryLists[this.userLists[i].inUserID].allCount[t].regist_time 
            && to >= this.summaryLists[this.userLists[i].inUserID].allCount[t].regist_time) {
              this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodTotalCount'] 
              += this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
              this.toAllMonthCount +=  this.summaryLists[this.userLists[i].inUserID].allCount[t].totalCount
            }
          }
          //絞込み期間の承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].approvalCount.length;t++) {
            if(from <= this.summaryLists[this.userLists[i].inUserID].approvalCount[t].regist_time 
            && to >= this.summaryLists[this.userLists[i].inUserID].approvalCount[t].regist_time) {
              this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodApprovalCount'] 
              += this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
              this.toAllMonthApprovalCount +=  this.summaryLists[this.userLists[i].inUserID].approvalCount[t].totalCount
            }
          }
          //絞込み期間の差し戻し数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].remandCount.length;t++) {
            if(from <= this.summaryLists[this.userLists[i].inUserID].remandCount[t].regist_time 
            && to >= this.summaryLists[this.userLists[i].inUserID].remandCount[t].regist_time) {
              this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodRemandCount'] 
              += this.summaryLists[this.userLists[i].inUserID].remandCount[t].totalCount
            }
          }
          //絞込み期間の未承認数
          for(let t = 0;t < this.summaryLists[this.userLists[i].inUserID].unapprovedCount.length;t++) {
            if(from <= this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].regist_time 
            && to >= this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].regist_time) {
              this.summaryLists[this.userLists[i].inUserID]['toMonthPeriodUnapprovedCount'] 
              += this.summaryLists[this.userLists[i].inUserID].unapprovedCount[t].totalCount
            }
          }
        }
      } else {
        for(let i = 0;i < this.searchLists.length;i++) {
          this.searchLists[i]['toMonthPeriodTotalCount'] = 0
          this.searchLists[i]['toMonthPeriodApprovalCount'] = 0
          this.searchLists[i]['toMonthPeriodRemandCount'] = 0
          this.searchLists[i]['toMonthPeriodUnapprovedCount']  = 0
          //絞込み期間の撮影数
          for(let t = 0;t < this.searchLists[i].allCount.length;t++) {
            if(from <= this.searchLists[i].allCount[t].regist_time 
            && to >= this.searchLists[i].allCount[t].regist_time) {
              this.searchLists[i]['toMonthPeriodTotalCount'] 
              += this.searchLists[i].allCount[t].totalCount
              this.toAllMonthCount +=  this.searchLists[i].allCount[t].totalCount
            }
          }
          //絞込み期間の承認数
          for(let t = 0;t < this.searchLists[i].approvalCount.length;t++) {
            if(from <= this.searchLists[i].approvalCount[t].regist_time 
            && to >= this.searchLists[i].approvalCount[t].regist_time) {
              this.searchLists[i]['toMonthPeriodApprovalCount'] 
              += this.searchLists[i].approvalCount[t].totalCount
              this.toAllMonthApprovalCount +=  this.searchLists[i].approvalCount[t].totalCount
            }
          }
          //絞込み期間の差し戻し数
          for(let t = 0;t < this.searchLists[i].remandCount.length;t++) {
            if(from <= this.searchLists[i].remandCount[t].regist_time 
            && to >= this.searchLists[i].remandCount[t].regist_time) {
              this.searchLists[i]['toMonthPeriodRemandCount'] 
              += this.searchLists[i].remandCount[t].totalCount
            }
          }
          //絞込み期間の未承認数
          for(let t = 0;t < this.searchLists[i].unapprovedCount.length;t++) {
            if(from <= this.searchLists[i].unapprovedCount[t].regist_time 
            && to >= this.searchLists[i].unapprovedCount[t].regist_time) {
              this.searchLists[i]['toMonthPeriodUnapprovedCount'] 
              += this.searchLists[i].unapprovedCount[t].totalCount
            }
          }
        }
      } 
      
    },

    filteringUserList(user,from,to) {
      if(from === '',to === '') {
        for(let i = 0; i < this.userLists.length;i++) {
          for(let p = 0;p < user.length;p++) {
            if(this.summaryLists[this.userLists[i].inUserID].userID === user[p]) {
              this.searchLists.push(this.summaryLists[this.userLists[i].inUserID])
            }
          }
        }
        this.toMonthTotalCount = 0
        this.toMonthApprovalCount = 0
        this.toAllMonthCount = 0
        this.toLastMonthTotalCount = 0
        this.toAllMonthApprovalCount = 0
        this.toLastMonthApprovalCount = 0
        for(let i = 0;i <this.searchLists.length;i++) {
          this.searchLists[i]['toMonthTotalCount']  = 0
          this.searchLists[i]['toMonthApprovalCount'] = 0
          this.searchLists[i]['toLastMonthTotalCount']  = 0
          this.searchLists[i]['toLastMonthApprovalCount']  = 0
          //当月トータル撮影数
          if(this.searchLists[i].allCount.length !== 0 &&
            this.searchLists[i].allCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthTotalCount'] 
              = this.searchLists[i].allCount[0].totalCount
              this.toMonthTotalCount += this.searchLists[i].allCount[0].totalCount
          } 
          //当月承認数
          if(this.searchLists[i].approvalCount.length !== 0 &&
            this.searchLists[i].approvalCount[0].regist_time == this.toDay) {
            this.searchLists[i]['toMonthApprovalCount'] 
              = this.searchLists[i].approvalCount[0].totalCount
            this.toMonthApprovalCount += this.searchLists[i].approvalCount[0].totalCount
          }
           //先月トータル撮影数
          for(let t = 0;t < this.searchLists[i].allCount.length;t++) {
            this.toAllMonthCount +=  this.searchLists[i].allCount[t].totalCount
            if(this.searchLists[i].allCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthTotalCount'] 
                = this.searchLists[i].allCount[t].totalCount
                this.toLastMonthTotalCount += this.searchLists[i].allCount[t].totalCount
            }
          }
          //先月承認数
          for(let t = 0;t < this.searchLists[i].approvalCount.length;t++) {
            this.toAllMonthApprovalCount +=  this.searchLists[i].approvalCount[t].totalCount
            if(this.searchLists[i].approvalCount[t].regist_time == this.toLastDay) {
              this.searchLists[i]['toLastMonthApprovalCount'] 
                = this.searchLists[i].approvalCount[t].totalCount
                this.toLastMonthApprovalCount += this.searchLists[i].approvalCount[t].totalCount
            }
          }
        }
      } else {
        this.toAllMonthCount = 0
        this.toAllMonthApprovalCount = 0
        from = moment(from).format("YYYY-MM")
        to = moment(to).format("YYYY-MM")
        for(let i = 0; i < this.userLists.length;i++) {
          for(let p = 0;p < user.length;p++) {
            if(this.summaryLists[this.userLists[i].inUserID].userID === user[p]) {
              this.searchLists.push(this.summaryLists[this.userLists[i].inUserID])
            }
          }
        }
        for(let i = 0; i < this.searchLists.length; i++) {
          for(let t = 0;t < this.searchLists[i].allCount.length;t++) {
            if(from <= this.searchLists[i].allCount[t].regist_time 
            && to >= this.searchLists[i].allCount[t].regist_time) {
              this.toAllMonthCount +=  this.searchLists[i].allCount[t].totalCount
            }
          }
          for(let t = 0;t < this.searchLists[i].approvalCount.length;t++) {
            if(from <= this.searchLists[i].approvalCount[t].regist_time 
              && to >= this.searchLists[i].approvalCount[t].regist_time) {
              this.toAllMonthApprovalCount +=  this.searchLists[i].approvalCount[t].totalCount
            }
          }
        }
      }
    },
    //投稿写真リスト取得
    processSummaryList() {
      this.getToMonthCount()
      this.getToLastMonthCount()
      this.getMonthPeriodCount('all','all','')
      this.showLoader = false
    },

    //ユーザー絞り込み
    filteringUser(checkUser) {
      // this.showLoader = true
      this.searchLists = []
      this.checkUser = checkUser
      this.filteringUserList(checkUser,this.searchFrom,this.searchTo)
      
    },
    //全ユーザー表示
    allUserList() {
      this.searchLists = []
      this.checkUser = []
      this.getToMonthCount()
      this.getToLastMonthCount()
      if(this.searchFrom !== '' || this.searchTo !== '') {
        this.getMonthPeriodCount(this.searchFrom,this.searchTo)
      } else {
        this.getMonthPeriodCount('all','all','')
      }
      
    }

  },
  watch:{
    'summaryLists':function() {
      if(typeof this.summaryLists !== 'undefined') {
        this.processSummaryList()
      }
    },
  }
}
</script>
<style scoped>
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(75vh);
    
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

</style>