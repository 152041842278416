<template>
  <div>
    <b-sidebar 
      id="userList" 
      title="ユーザー絞り込み" 
      shadow
      backdrop
      v-model="visible"
      no-header-close
    >
      <b-row 
        v-for="(userList, index) in userLists" 
        :key="'userList' + index"
        
      >
        <b-col 
          class="ms-5 mt-1"
        >
          <input
            name="users"
            v-model="checkUser" 
            class="form-check-input" 
            type="checkbox"  
            :id="userList.inUserID" 
            :value="userList.inUserID"
          />
          <label
            class="form-check-label" 
            :for="userList.inUserID"
          >
            {{ userList.vcUserLName }}
          </label>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col 
          class="text-center"
        >
          <b-button 
            variant="primary"
            size="lg"
            style="width:50%;"
            @click="searchUser"
          >
            絞り込む
          </b-button>
        </b-col>
      </b-row>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props:{
    checkUser:[],
    userLists:[]
  },
  data() {
    return {
      visible:false,
    }
  },
  methods: {
    searchUser() {
      this.visible = false
      this.$emit('searchUser',this.checkUser)
    }
  }
}
</script>